<template>
  <div>
    <v-bottom-navigation
      color="info"
      horizontal
      :background-color="colorfrist"
      dark
    >
      <v-btn to="/admin/timelines/transfer_38">
        <span>กำหนดการ </span>
        <v-icon>mdi-numeric-0-box</v-icon>
      </v-btn>

      <v-btn to="/admin/man_power_38">
        <span>ตำแหน่งว่างสถานศึกษา </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>    

      <v-btn to="/admin/transference_personnel_38">
        <span>ข้อมูลผู้ยืนย้าย </span>
        <v-icon>mdi-calculator</v-icon>
      </v-btn>
     <!--  <v-btn to="/admin/conditons_transfer_success">
        <span>สรุปผล</span>
        <v-icon>mdi-bookmark-check</v-icon>
      </v-btn>
      <v-btn to="/admin/Order_appoint">
        <span>รายงาน</span>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn to="/admin/conditons_transfer_success_reportmv">
        <span>รายงานตัว</span>
        <v-icon>mdi-account-check</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  name: "HRvecMovementmenu",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
  computed: {
    colorfrist() {
      return "#01579B";
    },
    colorsecord() {
      return "#004D40";
    }
  }
};
</script>

<style lang="scss" scoped></style>
